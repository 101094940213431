import React from 'react';
import { IHeaderContainerProps } from '../../../HeaderContainer.types';
import IronScreen from '../../../../ScreenWidthContainer/viewer/skinComps/IronScreen/IronScreen';
import HeaderContainer from '../../HeaderContainer';

const IronScreenHeader: React.FC<Omit<
  IHeaderContainerProps,
  'skin'
>> = props => <HeaderContainer {...props} skin={IronScreen}></HeaderContainer>;

export default IronScreenHeader;
